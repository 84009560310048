import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const createDeck = (numDecks) => {
  const suits = ['♠', '♥', '♦', '♣'];
  const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
  let deck = [];
  for (let i = 0; i < numDecks; i++) {
    for (let suit of suits) {
      for (let value of values) {
        deck.push({ value, suit });
      }
    }
  }
  return shuffleDeck(deck);
};

const shuffleDeck = (deck) => {
  for (let i = deck.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [deck[i], deck[j]] = [deck[j], deck[i]];
  }
  return deck;
};

const getCardValue = (card) => {
  if (!card || !card.value) return 0;
  if (['J', 'Q', 'K'].includes(card.value)) return 10;
  if (card.value === 'A') return 11;
  return parseInt(card.value);
};

const calculateHandValue = (hand) => {
  if (!hand || !Array.isArray(hand)) return 0;

  let value = hand.reduce((acc, card) => acc + getCardValue(card), 0);
  let aces = hand.filter(card => card && card.value === 'A').length;
  while (value > 21 && aces > 0) {
    value -= 10;
    aces -= 1;
  }
  return value;
};

const getRecommendedMove = (playerHand, dealerCard) => {
  const playerValue = calculateHandValue(playerHand);
  const isSoft = playerHand.some(card => card.value === 'A' && card.value + 10 <= 21);
  const dealerValue = getCardValue(dealerCard);

  // Pair Splitting
  if (playerHand.length === 2 && playerHand[0].value === playerHand[1].value) {
    const pairValue = playerHand[0].value;
    if (pairValue === 'A') return 'Split';
    if (pairValue === '8') return 'Split';
    if (pairValue === '10') return 'Stand';
    if (pairValue === '9') return (dealerValue === 7 || dealerValue === 10 || dealerValue === 11) ? 'Stand' : 'Split';
    if (pairValue === '7') return (dealerValue <= 7) ? 'Split' : 'Hit';
    if (pairValue === '6') return (dealerValue <= 6) ? 'Split' : 'Hit';
    if (pairValue === '5') return (dealerValue <= 9) ? 'Double Down' : 'Hit';
    if (pairValue === '4') return (dealerValue === 5 || dealerValue === 6) ? 'Split' : 'Hit';
    if (pairValue === '3' || pairValue === '2') return (dealerValue <= 7) ? 'Split' : 'Hit';
  }

  // Soft Hands
  if (isSoft) {
    if (playerValue === 19 || playerValue === 20) return 'Stand';
    if (playerValue === 18) return (dealerValue <= 6) ? 'Double Down' : 'Stand';
    if (playerValue === 17) return (dealerValue >= 3 && dealerValue <= 6) ? 'Double Down' : 'Hit';
    if (playerValue === 15 || playerValue === 16) return (dealerValue >= 4 && dealerValue <= 6) ? 'Double Down' : 'Hit';
    if (playerValue === 13 || playerValue === 14) return (dealerValue >= 5 && dealerValue <= 6) ? 'Double Down' : 'Hit';
  }

  // Hard Hands
  if (playerValue <= 8) return 'Hit';
  if (playerValue === 9) return (dealerValue >= 3 && dealerValue <= 6) ? 'Double Down' : 'Hit';
  if (playerValue === 10) return (dealerValue <= 9) ? 'Double Down' : 'Hit';
  if (playerValue === 11) return 'Double Down';
  if (playerValue === 12) return (dealerValue >= 4 && dealerValue <= 6) ? 'Stand' : 'Hit';
  if (playerValue >= 13 && playerValue <= 16) return (dealerValue <= 6) ? 'Stand' : 'Hit';
  return 'Stand';
};

const Blackjack = () => {
  const [numDecks, setNumDecks] = useState(2);
  const [deck, setDeck] = useState(createDeck(numDecks));
  const [playerHands, setPlayerHands] = useState([[]]);
  const [dealerHand, setDealerHand] = useState([]);
  const [dealerTurn, setDealerTurn] = useState(false);
  const [currentHandIndex, setCurrentHandIndex] = useState(0);
  const [playerOptionsDisabled, setPlayerOptionsDisabled] = useState(false);
  const [handMessages, setHandMessages] = useState([]);
  const [handResults, setHandResults] = useState([]);
  const [remainingCards, setRemainingCards] = useState(deck.length);
  const [handsPlayed, setHandsPlayed] = useState(0);
  const [handsWon, setHandsWon] = useState(0);
  const [correctMoves, setCorrectMoves] = useState(0);
  const [totalMoves, setTotalMoves] = useState(0);
  const [moveHistory, setMoveHistory] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const dealInitialCards = () => {
    const newDeck = [...deck];
    const playerHand = [newDeck.pop(), newDeck.pop()];
    const dealerHand = [newDeck.pop(), newDeck.pop()];

    setDeck(newDeck);
    setRemainingCards(newDeck.length);
    setPlayerHands([playerHand]);
    setDealerHand(dealerHand);
    setCurrentHandIndex(0);
    setPlayerOptionsDisabled(false);
    setHandMessages([]);
    setHandResults([]);
    setMoveHistory([]);
  };

  const validateMove = (playerMove, handIndex) => {
    const recommendedMove = getRecommendedMove(playerHands[handIndex], dealerHand[0]);
    setTotalMoves(prev => prev + 1);
    const newMessages = [...handMessages];
    if (playerMove === recommendedMove) {
      setCorrectMoves(prev => prev + 1);
      newMessages[handIndex] = 'Correct move!';
    } else {
      newMessages[handIndex] = `Wrong move! Recommended: ${recommendedMove}`;
    }
    setHandMessages(newMessages);
    setMoveHistory(prev => [...prev, { handIndex, playerMove, recommendedMove, result: newMessages[handIndex] }]);
  };

  const hit = () => {
    if (playerOptionsDisabled) return;
    validateMove('Hit', currentHandIndex);
    const newDeck = [...deck];
    const newCard = newDeck.pop();
    const newHands = [...playerHands];
    newHands[currentHandIndex] = [...newHands[currentHandIndex], newCard];
    setDeck(newDeck);
    setRemainingCards(newDeck.length);
    setPlayerHands(newHands);
  };

  const stand = () => {
    if (playerOptionsDisabled) return;
    validateMove('Stand', currentHandIndex);
    if (currentHandIndex < playerHands.length - 1) {
      setCurrentHandIndex(currentHandIndex + 1);
    } else {
      setPlayerOptionsDisabled(true);
      setDealerTurn(true);
      dealerPlay();
    }
  };

  const doubleDown = () => {
    if (playerOptionsDisabled) return;
    validateMove('Double Down', currentHandIndex);
    hit();
    setPlayerOptionsDisabled(true);
    setDealerTurn(true);
    dealerPlay();
  };

  const splitPairs = () => {
    if (playerOptionsDisabled) return;
    validateMove('Split', currentHandIndex);
    const newDeck = [...deck];
    const newHands = [...playerHands];
    const currentHand = newHands[currentHandIndex];

    if (currentHand && currentHand[0].value === currentHand[1].value) {
      const newHand1 = [currentHand[0], newDeck.pop()];
      const newHand2 = [currentHand[1], newDeck.pop()];
      newHands.splice(currentHandIndex, 1, newHand1, newHand2);
      setDeck(newDeck);
      setRemainingCards(newDeck.length);
      setPlayerHands(newHands);
    }
  };

  const takeInsurance = () => {
    // Implement insurance functionality
  };

  const dealerPlay = () => {
    let newDeck = [...deck];
    let newDealerHand = [...dealerHand];
    while (calculateHandValue(newDealerHand) < 17) {
      newDealerHand.push(newDeck.pop());
    }
    setDeck(newDeck);
    setRemainingCards(newDeck.length);
    setDealerHand(newDealerHand);
  };

  const resolveBets = () => {
    const dealerValue = calculateHandValue(dealerHand);
    const results = playerHands.map((hand, index) => {
      const playerValue = calculateHandValue(hand);
      if (playerValue > 21) {
        return 'LOSE';
      } else if (dealerValue > 21 || playerValue > dealerValue) {
        return 'WIN';
      } else if (dealerValue > playerValue) {
        return 'LOSE';
      } else {
        return 'PUSH';
      }
    });
    setHandResults(results);
  };

  useEffect(() => {
    dealInitialCards();
  }, [numDecks]);

  useEffect(() => {
    if (playerHands[currentHandIndex]) {
      const playerValue = calculateHandValue(playerHands[currentHandIndex]);
      if (playerValue > 21) {
        const newResults = [...handResults];
        newResults[currentHandIndex] = 'LOSE';
        setHandResults(newResults);
        setHandsPlayed(prev => prev + 1);
        if (currentHandIndex < playerHands.length - 1) {
          setCurrentHandIndex(currentHandIndex + 1);
        } else {
          setPlayerOptionsDisabled(true);
          setDealerTurn(true);
          dealerPlay();
        }
      } else if (playerValue === 21) {
        if (currentHandIndex < playerHands.length - 1) {
          setCurrentHandIndex(currentHandIndex + 1);
        } else {
          setPlayerOptionsDisabled(true);
        }
      }
    }
  }, [playerHands, currentHandIndex]);

  useEffect(() => {
    if (playerOptionsDisabled && handResults.length !== playerHands.length) {
      resolveBets();
      setDealerTurn(true);
    }
  }, [dealerHand, playerOptionsDisabled, playerHands, handResults]);

  const handleDeckChange = (event) => {
    const selectedDecks = parseInt(event.target.value);
    setNumDecks(selectedDecks);
    setDeck(createDeck(selectedDecks));
  };

  const startNewRound = () => {
    setDeck(createDeck(numDecks));
    setRemainingCards(deck.length);
    setPlayerHands([[]]);
    setDealerHand([]);
    setCurrentHandIndex(0);
    setPlayerOptionsDisabled(false);
    setDealerTurn(false);
    setHandMessages([]);
    setHandResults([]);
    setMoveHistory([]);
    dealInitialCards();
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const lightModeColors = {
    backgroundColor: '#fff',
    textColor: '#333',
    buttonColor: '#67a4f6', // Light blue color
    headerBackgroundColor: '#fff',
    headerTextColor: '#333',
    cardBackgroundColor: '#fff',
  };

  const darkModeColors = {
    backgroundColor: '#333',
    textColor: '#fff',
    buttonColor: '#67a4f6', // Light blue color
    headerBackgroundColor: '#444',
    headerTextColor: '#fff',
    cardBackgroundColor: '#fff',
  };

  const colors = darkMode ? darkModeColors : lightModeColors;

  const cardStyle = {
    border: `2px solid ${colors.textColor}`,
    borderRadius: '8px',
    padding: '4px',
    margin: '4px',
    display: 'inline-block',
    backgroundColor: colors.cardBackgroundColor,
    fontSize: '20px',
    fontWeight: 'bold',
    color: darkMode ? '#000' : colors.textColor, // Ensure visibility in dark mode
    boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
    width: '50px',
    height: '75px',
    textAlign: 'center',
    lineHeight: '60px',
  };

  const hiddenCardStyle = {
    ...cardStyle,
    backgroundColor: colors.cardBackgroundColor,
    color: colors.textColor,
    textAlign: 'center',
    lineHeight: '60px',
  };

  const handContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: colors.backgroundColor,
    border: `2px solid ${colors.textColor}`,
    color: colors.textColor,
    textAlign: 'center',
    display: 'inline-block',
    width: '45%',
    minWidth: '300px',
    position: 'relative',
  };

  const scoreStyle = {
    backgroundColor: colors.buttonColor,
    color: colors.textColor,
    padding: '5px 10px',
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: '14px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    marginTop: '10px',
  };

  const labelStyle = {
    backgroundColor: colors.buttonColor,
    color: colors.textColor,
    padding: '5px 10px',
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    marginBottom: '10px',
  };

  const statsContainerStyle = {
    padding: '10px',
    backgroundColor: colors.backgroundColor,
    borderRadius: '10px',
    border: `2px solid ${colors.textColor}`,
    color: colors.textColor,
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '14px',
    lineHeight: '1.4',
    maxWidth: '90%',
    margin: '0 auto',
  };

  const buttonStyle = {
    padding: '8px 16px',
    margin: '5px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: colors.buttonColor,
    color: colors.textColor,
    fontSize: '12px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  };

  const chipButtonStyle = {
    ...buttonStyle,
    backgroundColor: colors.buttonColor,
    width: '60px',
    margin: '10px',
  };

  const tableStyle = {
    backgroundColor: colors.backgroundColor,
    padding: '10px',
    width: '100%', // Ensure it takes full width
    maxWidth: '1200px', // Maximum width to ensure it fits well on all screens
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    flexGrow: 1,
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.headerBackgroundColor,
    color: colors.headerTextColor,
    padding: '10px',
    position: 'sticky',
    top: '0',
    zIndex: 1000,
    fontSize: '18px',
    fontWeight: 'bold',
    width: '100%',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
  };

  const titleStyle = {
    textAlign: 'left',
    flex: 1,
    marginLeft: '10px',
  };

  const menuButtonStyle = {
    backgroundColor: colors.buttonColor,
    color: colors.textColor,
    border: 'none',
    borderRadius: '5px',
    padding: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginRight: '10px',
    display: window.innerWidth >= 768 ? 'none' : 'block', // Hide on desktop view
  };

  const dropdownMenuStyle = {
    position: 'absolute',
    top: '60px',
    left: '0',
    backgroundColor: `${colors.backgroundColor}CC`, // Semi-transparent background
    color: darkMode ? '#000' : colors.textColor, // Ensure visibility in dark mode
    borderRadius: '5px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1001,
    padding: '10px 20px',
    display: window.innerWidth >= 768 || menuOpen ? 'block' : 'none',
    fontSize: '14px',
    maxWidth: '240px',
    marginTop: '10px',
    width: '240px', // Ensure consistent width
    height: 'auto', // Adjust height to content
    overflowY: 'auto', // Add scroll if content overflows
  };

  const menuItemStyle = {
    padding: '10px 0',
    borderBottom: `1px solid ${colors.textColor}`,
    textAlign: 'left',
  };

  const menuIconStyle = {
    marginRight: '10px',
    verticalAlign: 'middle',
  };

  const mobileContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  };

  const tableContainerStyle = {
    width: '100%',
    margin: '20px auto',
    borderCollapse: 'collapse',
    border: `1px solid ${colors.textColor}`,
  };

  const tableHeaderStyle = {
    backgroundColor: colors.buttonColor,
    color: colors.textColor,
    padding: '10px',
    borderBottom: `2px solid ${colors.textColor}`,
    fontWeight: 'bold',
  };

  const tableRowStyle = {
    borderBottom: `1px solid ${colors.textColor}`,
  };

  const tableCellStyle = {
    padding: '10px',
    textAlign: 'center',
  };

  const howToContainerStyle = {
    padding: '20px',
    color: colors.textColor,
    fontSize: '16px',
    lineHeight: '1.6',
    maxWidth: '700px',
    margin: '0 auto',
    textAlign: 'left',
  };

  const diagramContainerStyle = {
    margin: '20px 0',
    textAlign: 'center',
  };

  const diagramImageStyle = {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
  };

  return (
    <Router>
      <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: colors.backgroundColor, color: colors.textColor, overflowX: 'hidden', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <header style={headerStyle}>
          <div style={titleStyle}>Blackjack</div>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <button style={menuButtonStyle} onClick={() => setMenuOpen(!menuOpen)}>☰</button>
            <label style={{ marginLeft: '10px', color: colors.headerTextColor, display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} style={{ marginRight: '5px', cursor: 'pointer' }} />
              <span>🌙</span>
            </label>
          </div>
        </header>
        <div style={dropdownMenuStyle}>
          <nav>
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li style={menuItemStyle}>
                <span role="img" aria-label="game" style={menuIconStyle}>🎲</span>
                <Link to="/" style={{ textDecoration: 'none', color: darkMode ? '#000' : colors.textColor }}>Game</Link>
              </li>
              <li style={menuItemStyle}>
                <span role="img" aria-label="past games" style={menuIconStyle}>🗂️</span>
                <Link to="/past-hands" style={{ textDecoration: 'none', color: darkMode ? '#000' : colors.textColor }}>Past Hands</Link>
              </li>
              <li style={menuItemStyle}>
                <span role="img" aria-label="strategy" style={menuIconStyle}>📈</span>
                <Link to="/strategy" style={{ textDecoration: 'none', color: darkMode ? '#000' : colors.textColor }}>Strategy</Link>
              </li>
              <li style={menuItemStyle}>
                <span role="img" aria-label="how-to" style={menuIconStyle}>📖</span>
                <Link to="/how-to" style={{ textDecoration: 'none', color: darkMode ? '#000' : colors.textColor }}>How-To</Link>
              </li>
              <li style={menuItemStyle}>
                <span role="img" aria-label="about" style={menuIconStyle}>ℹ️</span>
                <Link to="/about" style={{ textDecoration: 'none', color: darkMode ? '#000' : colors.textColor }}>About</Link>
              </li>
              <li style={menuItemStyle}>
                <span role="img" aria-label="decks" style={menuIconStyle}>🃏</span>
                <label htmlFor="deck-select">Number of Decks:</label>
                <select id="deck-select" value={numDecks} onChange={handleDeckChange} style={{ marginLeft: '10px', color: darkMode ? '#000' : colors.textColor }}>
                  {[...Array(8).keys()].map(i => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </li>
            </ul>
          </nav>
        </div>
        <div style={tableStyle}>
          <Routes>
            <Route path="/" element={
              <>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', position: 'relative' }}>
                  <div style={handContainerStyle}>
                    <h2 style={labelStyle}>Dealer</h2>
                    <div>
                      {dealerHand.map((card, index) => (
                        <span key={index} style={index === 1 && !dealerTurn ? hiddenCardStyle : cardStyle}>
                          {index === 1 && !dealerTurn ? '🂠' : `${card.value}${card.suit}`}
                        </span>
                      ))}
                    </div>
                    <h2 style={scoreStyle}>Score: {dealerTurn ? calculateHandValue(dealerHand) : calculateHandValue([dealerHand[0]])}</h2>
                  </div>
                </div>
                <div style={mobileContainerStyle}>
                  {playerHands.map((hand, index) => (
                    <div key={index} style={handContainerStyle}>
                      <h2 style={labelStyle}>Player {index + 1}</h2>
                      <div>
                        {hand.map((card, cardIndex) => (
                          <span key={cardIndex} style={cardStyle}>
                            {card.value}{card.suit}
                          </span>
                        ))}
                      </div>
                      <h2 style={scoreStyle}>Score: {calculateHandValue(hand)}</h2>
                      {index === currentHandIndex && !playerOptionsDisabled && (
                        <div style={{ marginTop: '10px' }}>
                          <button style={playerOptionsDisabled ? disabledButtonStyle : buttonStyle} onClick={hit} disabled={playerOptionsDisabled}>Hit</button>
                          <button style={playerOptionsDisabled ? disabledButtonStyle : buttonStyle} onClick={stand} disabled={playerOptionsDisabled}>Stand</button>
                          <button style={playerOptionsDisabled ? disabledButtonStyle : buttonStyle} onClick={doubleDown} disabled={playerOptionsDisabled}>Double Down</button>
                          <button style={playerOptionsDisabled ? disabledButtonStyle : buttonStyle} onClick={splitPairs} disabled={playerOptionsDisabled || hand.length !== 2 || hand[0]?.value !== hand[1]?.value}>Split Pairs</button>
                          <button style={playerOptionsDisabled ? disabledButtonStyle : buttonStyle} onClick={takeInsurance} disabled={playerOptionsDisabled || dealerHand[0]?.value !== 'A'}>Take Insurance</button>
                        </div>
                      )}
                      {handMessages[index] && (
                        <div style={{ marginTop: '10px' }}>
                          <h3 style={{ color: colors.textColor }}>{handMessages[index]}</h3>
                        </div>
                      )}
                      {handResults[index] && (
                        <div style={{ marginTop: '10px' }}>
                          <h3 style={{ color: handResults[index] === 'WIN' ? '#67a4f6' : handResults[index] === 'LOSE' ? '#67a4f6' : colors.textColor }}>
                            {handResults[index]}
                          </h3>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {playerOptionsDisabled && (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <button style={buttonStyle} onClick={startNewRound}>Play Another Round</button>
                  </div>
                )}
                {window.innerWidth < 768 && (
                  <div style={{ ...statsContainerStyle, margin: '0 auto', width: '100%' }}>
                    <h3>Remaining Cards: {remainingCards}</h3>
                    <h3>Hands Played: {handsPlayed}</h3>
                    <h3>Hands Won: {handsWon}</h3>
                    <h3>Total Moves: {totalMoves}</h3>
                    <h3>Correct Moves: {correctMoves}</h3>
                  </div>
                )}
              </>
            } />
            <Route path="/past-hands" element={
              <div style={{ padding: '20px', color: colors.textColor }}>
                <h2>Past Hands</h2>
                <table style={tableContainerStyle}>
                  <thead>
                    <tr style={tableHeaderStyle}>
                      <th style={tableCellStyle}>Hand #</th>
                      <th style={tableCellStyle}>Player Move</th>
                      <th style={tableCellStyle}>Recommended Move</th>
                      <th style={tableCellStyle}>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {moveHistory.map((move, index) => (
                      <tr key={index} style={tableRowStyle}>
                        <td style={tableCellStyle}>{move.handIndex + 1}</td>
                        <td style={tableCellStyle}>{move.playerMove}</td>
                        <td style={tableCellStyle}>{move.recommendedMove}</td>
                        <td style={tableCellStyle}>{move.result}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            } />
            <Route path="/strategy" element={
              <div style={{ padding: '20px', color: colors.textColor }}>
                <h2>Strategy</h2>
                <p>Follow these guidelines to make the best possible move in Blackjack:</p>
                <ul>
                  <li>Always split Aces and 8s.</li>
                  <li>Never split 10s.</li>
                  <li>Double down on 11 unless the dealer shows an Ace.</li>
                  <li>Stand on 17 or higher.</li>
                  <li>Hit if you have 12-16 and the dealer shows 7 or higher.</li>
                  <li>Stand if you have 12-16 and the dealer shows 2-6.</li>
                </ul>
              </div>
            } />
            <Route path="/how-to" element={
              <div style={howToContainerStyle}>
                <h2>How to Play Blackjack</h2>
                <p>The goal of Blackjack is to get a hand value of 21 or as close to it as possible without going over.</p>
                <p>Card values:</p>
                <ul>
                  <li>Number cards are worth their face value.</li>
                  <li>Face cards (Jack, Queen, King) are worth 10.</li>
                  <li>Aces can be worth 1 or 11, depending on which is more favorable.</li>
                </ul>
                <p>Basic rules:</p>
                <ul>
                  <li>Each player is dealt two cards.</li>
                  <li>The dealer also receives two cards, with one card face up.</li>
                  <li>Players can choose to hit (take another card) or stand (keep their current hand).</li>
                  <li>If a player's hand exceeds 21, they bust and lose.</li>
                  <li>The dealer must hit until they reach at least 17.</li>
                  <li>If the dealer busts, any remaining players win.</li>
                </ul>
                <div style={diagramContainerStyle}>
                  <img src="path/to/diagram1.png" alt="Blackjack Diagram 1" style={diagramImageStyle} />
                </div>
                <p>Advanced options:</p>
                <ul>
                  <li>Double Down: You can double your bet after the initial cards are dealt, but you must take one more card.</li>
                  <li>Split: If you have two cards of the same value, you can split them into two separate hands.</li>
                  <li>Insurance: If the dealer's face-up card is an Ace, you can take insurance, betting that the dealer has Blackjack.</li>
                </ul>
                <div style={diagramContainerStyle}>
                  <img src="path/to/diagram2.png" alt="Blackjack Diagram 2" style={diagramImageStyle} />
                </div>
              </div>
            } />
            <Route path="/about" element={<h2 style={{ color: colors.textColor }}>About Page</h2>} />
          </Routes>
        </div>
        {window.innerWidth >= 768 && (
          <div style={{ position: 'absolute', top: '80px', right: '10px', maxWidth: '300px' }}>
            <div style={statsContainerStyle}>
              <h3>Remaining Cards: {remainingCards}</h3>
              <h3>Hands Played: {handsPlayed}</h3>
              <h3>Hands Won: {handsWon}</h3>
              <h3>Total Moves: {totalMoves}</h3>
              <h3>Correct Moves: {correctMoves}</h3>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
};

export default Blackjack;
